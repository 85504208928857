<template>
  <div class="container" v-if="isMobile">
    <div class="pageData-title">
      <div
        v-for="(item, key) in titleList"
        :key="key"
        @click="changeTab(key)"
        class="title"
        :class="{ activeTitle: activeKey === key }"
      >
        {{ item }}
      </div>
      <!-- <div class="title">
        <router-link to="/Map" target="_blank"> 山海地図 </router-link>
      </div> -->
    </div>

    <div class="pageData-wrap" v-show="!isShowDetail">
      <div class="pageData-content" v-show="activeKey === 0">
        <div
          class="book-wrap"
          v-for="(book, key) in bookList"
          :to="book.link"
          :key="key"
          @click="showDetail(book.mid)"
        >
          <span>{{ book.name }}</span>
        </div>
      </div>
      <div class="pageData-content" v-show="activeKey === 1">
        <div v-for="item in 3" :key="item">
          <div v-for="video in 4" :key="video">
            {{ video }}
          </div>
        </div>
      </div>
      <div class="pageData-content" v-show="activeKey === 2">
        <div v-for="item in 3" :key="item">
          <div v-for="video in 4" :key="video">
            {{ video }}
          </div>
        </div>
      </div>
    </div>

    <div class="detail-container" v-show="isShowDetail">
      <div class="detail-left">
        <div
          class="title-wrap"
          v-for="(item, key) in bookList"
          :key="key"
          :class="{ activeGroup: checkGroup === item.mid }"
          @click.stop="handleGroup(item.mid)"
        >
          <div class="group">
            <div>{{ item.name }}</div>
          </div>

          <div
            class="pagedataArrow left"
            @click.stop="nextRead(false)"
            :class="{ disabled: translateX >= 0 }"
            v-show="checkGroup === item.mid && groupItemLIst.length > 0"
          >
            &lt;
          </div>

          <div
            class="group-content"
            v-show="checkGroup === item.mid"
            ref="groupScroll"
          >
            <ul
              class="group-item-list"
              :style="{ transform: `translateX(${translateX}rem)` }"
            >
              <li
                v-for="(groupItem, translateKey) in groupItemLIst"
                :key="translateKey"
                @click.stop="read(groupItem.id, translateKey)"
                :class="{ activeReadId: readId === groupItem.id }"
              >
                <div>{{ groupItem.title }}</div>
              </li>
            </ul>
          </div>

          <div
            class="pagedataArrow right"
            @click.stop="nextRead(true)"
            :class="{ disabled: translateX <= -(groupItemLIst.length - 7) / 2 }"
            v-show="checkGroup === item.mid && groupItemLIst.length > 0"
          >
            &gt;
          </div>
        </div>
      </div>
      <div class="detail-right">
        <div class="content-top"></div>
        <div class="content-wrap">
          <div class="content book-content">
            <div
              v-if="checkGroup == 15 && attributes.key1"
              class="hasKeyDetail"
            >
              <div v-if="audioSrc" class="audio-wrap" @click="playAudio()">
                <img src="../../assets/img/laba.png" alt="" />
                <audio :src="audioSrc" ref="vd"></audio>
              </div>
              <img class="articleImg" :src="articleContentImg" alt="" />
              <div class="title">
                {{ personage }}
              </div>
              <div class="attributes-item">
                <div class="identity">身分：{{ attributes.key1 }}</div>
              </div>
              <div class="attributes-item">
                <div class="godhood">神格：{{ attributes.key2 }}</div>
              </div>
              <div class="attributes-item">
                <div class="interest">趣味：{{ attributes.key3 }}</div>
              </div>
              <div class="attributes-item">
                <div class="power">勢力： {{ attributes.key4 }}</div>
              </div>
              <div class="attributes-item">
                <div class="interest">武器：{{ attributes.key5 }}</div>
              </div>
              <div class="attributes-item">
                <div class="power">宝器： {{ attributes.key6 }}</div>
              </div>
              <div class="rl">功法： {{ attributes.key7 }}</div>
              <div class="rl">功法紹介： {{ attributes.key8 }}</div>
              <div class="rl">生涯の功績： {{ attributes.key9 }}</div>
            </div>

            <div
              v-if="checkGroup == 14 && attributes.k1"
              class="contentKeyDetail"
            >
              <img :src="articleContentImg" alt="" />
              <div class="title">
                {{ personage }}
              </div>
              <div class="attributes-item">
                <div class="identity">名称：{{ attributes.k1 }}</div>
              </div>
              <div class="attributes-item">
                <div>使用者：{{ attributes.k2 }}</div>
              </div>
              <div class="attributes-item">ランク：{{ attributes.k3 }}</div>

              <div class="attributes-item">神器紹介：{{ attributes.k4 }}</div>
              <div class="attributes-item" v-html="articleContent"></div>
            </div>

            <div class="expect" v-if="isShowExpect">
              <div>乞うご期待</div>
            </div>
          </div>
        </div>
        <div class="content-bottom"></div>
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <div class="pageData-title">
      <div
        v-for="(item, key) in titleList"
        :key="key"
        @click="changeTab(key)"
        class="title"
        :class="{ activeTitle: activeKey === key }"
      >
        {{ item }}
      </div>
      <!-- <div class="title">
        <router-link to="/Map" target="_blank"> 山海地図 </router-link>
      </div> -->
    </div>

    <div class="pageData-wrap" v-show="!isShowDetail">
      <div class="pageData-content" v-show="activeKey === 0">
        <div
          class="book-wrap"
          v-for="(book, key) in bookList"
          :to="book.link"
          :key="key"
          @click="showDetail(book.mid)"
        >
          <span>{{ book.name }}</span>
        </div>
      </div>
      <div class="pageData-content" v-show="activeKey === 1">
        <div v-for="item in 3" :key="item">
          <div v-for="video in 4" :key="video">
            {{ video }}
          </div>
        </div>
      </div>
      <div class="pageData-content" v-show="activeKey === 2">
        <div v-for="item in 3" :key="item">
          <div v-for="video in 4" :key="video">
            {{ video }}
          </div>
        </div>
      </div>
    </div>

    <div class="detail-container" v-show="isShowDetail">
      <div class="detail-left">
        <div
          class="title-wrap"
          v-for="(item, key) in bookList"
          :key="key"
          :class="{ activeGroup: checkGroup === item.mid }"
          @click.stop="handleGroup(item.mid)"
        >
          <div class="group">{{ item.name }}</div>
          <div
            class="group-content"
            v-show="checkGroup === item.mid && !isShowExpect"
            ref="groupScroll"
          >
            <ul
              class="group-item-list"
              :style="{ transform: `translateX(${translateX}em)` }"
            >
              <li
                v-for="(groupItem, translateKey) in groupItemLIst"
                :key="translateKey"
                @click.stop="read(groupItem.id, translateKey)"
                :class="{ activeReadId: readId === groupItem.id }"
              >
                <div>{{ groupItem.title }}</div>
              </li>
            </ul>
          </div>
          <div
            class="pagedataArrow left"
            @click.stop="nextRead(true)"
            :class="{ disabled: translateX >= (groupItemLIst.length - 4) * 2 }"
            v-show="checkGroup === item.mid && !isShowExpect"
          >
            &lt;
          </div>
          <div
            class="pagedataArrow right"
            @click.stop="nextRead(false)"
            :class="{ disabled: translateX <= 0 }"
            v-show="checkGroup === item.mid && !isShowExpect"
          >
            &gt;
          </div>
        </div>
      </div>
      <div class="detail-right">
        <div class="content-wrap">
          <div
            v-show="!isShowExpect"
            class="to-left"
            @click="toLeft()"
            :class="{ disabledLeft: scrollLeftDisabled }"
          ></div>
          <div class="wrapper" ref="wrapper">
            <div class="content book-content" ref="content">
              <div
                v-if="checkGroup == 16"
                v-html="articleContent"
                class="noneKeyDetail"
              ></div>
              <div
                v-if="checkGroup == 15 && attributes.key1"
                class="hasKeyDetail"
              >
                <div class="detail-header">
                  <div v-if="audioSrc" class="audio-wrap" @click="playAudio()">
                    <img src="../../assets/img/laba.png" alt="" />
                    <audio :src="audioSrc" ref="vd"></audio>
                  </div>
                  <div class="img-load-wrap">
                    <img
                      v-show="isImgLoad"
                      @load="imgLoad"
                      :src="articleContentImg"
                      alt=""
                      class="articleImg"
                    />
                  </div>
                </div>

                <div class="title">
                  {{ personage }}
                </div>

                <div class="attributes-item">
                  <!-- <div class="identity">身分：<span>{{ attributes.key1 }}</span> </div> -->
                  <div class="identity">
                    <p>{{ attributes.key1 }}</p>
                    <strong>:身分</strong>
                  </div>
                </div>
                <div class="attributes-item">
                  <div class="godhood">
                    <p>{{ attributes.key2 }}</p>
                    <strong>:神格</strong>
                  </div>
                </div>
                <div class="attributes-item">
                  <div class="interest">
                    <p>{{ attributes.key3 }}</p>
                    <strong>:趣味</strong>
                  </div>
                  <!-- <div class="power">勢力： {{ attributes.key4 }}</div> -->
                </div>
                <div class="attributes-item">
                  <div class="power">
                    <p>{{ attributes.key4 }}</p>
                    <strong>:勢力</strong>
                  </div>
                </div>
                <div class="attributes-item">
                  <div class="interest">
                    <p>
                      {{ attributes.key5 }}
                    </p>
                    <strong>:武器</strong>
                  </div>
                  <!-- <div class="power">宝器： {{ attributes.key6 }}</div> -->
                </div>
                <div class="attributes-item">
                  <div class="power">
                    <p>{{ attributes.key6 }}</p>
                    <strong>:宝器</strong>
                  </div>
                </div>
                <div class="attributes-item">
                  <div class="power">
                    <p>{{ attributes.key7 }}</p>
                    <strong>:功法</strong>
                  </div>
                </div>
                <!-- <div class="godhood">功法:{{ attributes.key7 }}</div> -->
                <div class="attributes-item">
                  <div><strong>:功法紹介</strong></div>
                </div>
                <div class="rl">
                  {{ attributes.key8 }}
                </div>
                <div class="attributes-item">
                  <div><strong>:生涯の功績</strong></div>
                </div>
                <div class="rl">{{ attributes.key9 }}</div>
                <!-- </div> -->
              </div>
              <div
                v-if="checkGroup == 14 && attributes.k1"
                class="contentKeyDetail"
              >
                <img :src="articleContentImg" alt="" class="articleImg" />
                <div class="title">
                  {{ personage }}
                </div>
                <div class="attributes-item">
                  <div class="identity">
                    <i>
                      {{ attributes.k1 }}
                    </i>
                    <strong>:使用者</strong>
                  </div>
                </div>
                <div class="attributes-item">
                  <div>
                    <i>{{ attributes.k2 }}</i>
                    <strong>:ランク</strong>
                  </div>
                </div>
                <!-- <div class="godhood"><strong>ランク：</strong>{{ attributes.k3 }}</div> -->
                <div class="attributes-item">
                  <div>
                    <strong>:神器紹介</strong>
                  </div>
                </div>
                <div class="rl">
                  {{ attributes.k3 }}
                </div>
                <div class="attributes-item">
                  <div>
                    <strong>:神器歴史</strong>
                  </div>
                </div>
                <div class="contentKeyContent" v-html="articleContent"></div>
              </div>
              <div class="expect" v-if="isShowExpect">
                <div>乞うご期待</div>
              </div>
            </div>
          </div>

          <div
            v-show="!isShowExpect"
            class="to-right"
            @click="toRight()"
            :class="{ disabledRight: scrollRightDisabled }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import { mapMutations } from "vuex";
import { getArticleList, getArticle, getChildList } from "../../api/index";
import { isMobile } from "@/utils/util";

// import NoneKeyDetail from './child/NoneKeyDetail.vue';

export default {
  name: "PageData",
  // components: {NoneKeyDetail},
  data() {
    return {
      titleList: ["ゲーム資料"],
      isMobile,
      // titleList: ["ゲーム資料", "山海資料"],
      contentList: [0, 1],
      bookList: [],
      activeKey: 0,
      isShowDetail: false,
      checkGroup: 0,
      scroll: null,
      scrollLeft: 0,
      groupItemLIst: [],
      readId: 0,
      groupScroll: null,
      translateX: 0,
      articleContentImg: "",
      articleContent: "",
      isImgLoad: false,
      mapList: [],
      figureList: [],
      treasureList: [],
      devIlList: [],
      attributes: {},
      personage: "",
      scrollRightDisabled: true,
      scrollLeftDisabled: false,
      isShowExpect: false,
      audioSrc: "",
    };
  },
  methods: {
    ...mapMutations(["increment"]),
    changeTab(key) {
      console.log(key);
      this.activeKey = key;
      this.isShowDetail = false;
    },
    showDetail(mid) {
      this.isShowDetail = true;
      // this.requestList(mid);
      this.handleGroup(mid);
    },
    async handleGroup(mid) {
      console.log(mid);
      this.checkGroup = mid;
      this.translateX = 0;
      if (!isMobile) {
        this.$refs.content.style.transform = `translateX(0)`;
      }

      this.scrollLeft = 0;
      // this.readId = 0;
      // this.requestList(mid);
      // console.log(mid);
      let groupItemLIst = [];
      this.scrollLeftDisabled = false;
      this.scrollRightDisabled = false;
      switch (mid) {
        case "16":
          // 山海洪图
          groupItemLIst = this.mapList;
          break;
        case "15":
          // 人物志
          groupItemLIst = this.figureList;
          break;
        case "14":
          // 灵宝录
          groupItemLIst = this.treasureList;
          break;
        case "13":
          // 妖魔志
          groupItemLIst = this.devIlList;
          break;
        default:
          console.log("结束");
          break;
      }
      console.log(groupItemLIst);
      if (groupItemLIst) {
        this.isShowExpect = false;
        this.groupItemLIst = groupItemLIst;

        if (groupItemLIst.length > 0) {
          const id = groupItemLIst[0].id;
          this.requestDetaile(parseInt(id));
          // console.log(detail);
          this.readId = id;
        }
      } else {
        this.isShowExpect = true;
        this.groupItemLIst = [];
      }
    },
    toLeft() {
      const content = this.$refs.content;
      // console.log(this.$refs);
      // offsetWidth 878  scrollWidth 1571
      const offsetWidth = content.children[0].offsetWidth;
      const scrollWidth = content.children[0].scrollWidth;
      // console.log(offsetWidth);
      const left = this.scrollLeft + 250;
      // console.log(left);
      if (left + offsetWidth - scrollWidth < 250) {
        this.scrollLeft = left;
        content.style.transform = `translateX(${left}px)`;
        content.style.transitionDuration = "2s";
        this.scrollRightDisabled = false;
      } else {
        const list = this.groupItemLIst;
        content.style.transitionDuration = "0s";
        let readKey = 0;
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === this.readId) {
            readKey = i;
            break;
          }
        }
        if (readKey === list.length - 1) {
          this.scrollLeftDisabled = true;
        } else {
          this.read(list[readKey + 1].id, readKey + 1);
        }
      }
    },
    toRight() {
      const content = this.$refs.content;
      const left = this.scrollLeft - 250;
      if (left > 0) {
        this.scrollLeft = left;
        content.style.transform = `translateX(${left}px)`;
        this.scrollLeftDisabled = false;
        content.style.transitionDuration = "2s";
      } else {
        this.scrollLeft = 0;
        content.style.transform = `translateX(0)`;
        content.style.transitionDuration = "0s";
        const list = this.groupItemLIst;
        let readKey = 0;
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === this.readId) {
            readKey = i;
            break;
          }
        }
        if (readKey === 0) {
          this.scrollRightDisabled = true;
        } else {
          this.read(list[readKey - 1].id, readKey - 1);
        }
      }
    },
    menu() {},
    read(id, translateKey) {
      this.readId = id;
      this.scrollLeft = 0;
      this.requestDetaile(id);

      this.scrollLeftDisabled = false;
      if (translateKey == 0) {
        this.scrollRightDisabled = true;
      } else {
        this.scrollRightDisabled = false;
      }
      if (isMobile) {
        debugger; // eslint-disable-line
        if (translateKey <= this.groupItemLIst.length - 5 && translateKey > 3) {
          this.translateX = -(translateKey - 1) * 0.2;
        } else if (translateKey <= 3) {
          this.translateX = 0;
        } else {
          this.translateX = -(this.groupItemLIst.length - 7) * 0.5;
        }
      } else {
        if (translateKey <= this.groupItemLIst.length - 4 && translateKey > 1) {
          this.translateX = (translateKey - 1) * 2;
        } else if (translateKey <= 1) {
          this.translateX = 0;
        } else {
          this.translateX = (this.groupItemLIst.length - 4) * 2;
        }
      }
    },
    nextRead(value) {
      const translateX = this.translateX;
      // debugger // eslint-disable-line
      if (isMobile) {
        console.log(translateX, -(this.groupItemLIst.length - 7) / 2);
        if (value) {
          if (translateX > -(this.groupItemLIst.length - 7) / 2) {
            this.translateX = translateX - 0.8;
          }
        } else {
          if (translateX < 0) {
            this.translateX = translateX + 0.8;
          }
        }
      } else {
        if (value) {
          if (translateX < (this.groupItemLIst.length - 4) * 2) {
            this.translateX = translateX + 2;
          }
        } else {
          if (translateX > 0) {
            this.translateX = translateX - 2;
          }
        }
      }
    },
    requestList(mid) {
      getArticleList({ mid }).then(({ data }) => {
        console.log(data);
        this.groupItemLIst = data.list;
        const id = data.list[0].id;
        this.readId = id;
        this.requestDetaile(parseInt(id));
      });
    },
    requestDetaile(id) {
      const checkGroup = this.checkGroup;
      this.isImgLoad = false;
      this.articleContentImg = "";
      getArticle({ id }).then(({ data }) => {
        console.log(data.details);
        if (!isMobile) {
          this.$refs.content.style.transform = `translateX(0)`;
        }

        const { img, content, attributes, title, links } = data.details;
        if (checkGroup == 16) {
          this.articleContent =
            `<img class="articleImg" src="${img}" /><div class="title"><span>${title}</span></div>` +
            this.escape2Html(content);
        } else if (checkGroup == 15) {
          this.articleContentImg = img;
          console.log(attributes);
          this.attributes = attributes;
          this.personage = title;
        } else if (checkGroup == 14) {
          this.articleContentImg = img;
          console.log(attributes);
          this.attributes = attributes;
          this.personage = title;
          this.articleContent = this.escape2Html(content);
        }
        if (links.audio) {
          this.audioSrc = links.audio;
        } else {
          this.audioSrc = "";
        }
      });
    },
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    async firstGetAllData() {
      try {
        const firstList = await getChildList({ mid: 12 });
        const bookList = firstList.data;
        this.bookList = bookList;
        // console.log(bookList);
        for (const second of bookList) {
          // console.log(second);
          getArticleList({ mid: second.mid }).then(({ data }) => {
            // console.log(data);
            switch (second.mid) {
              case "16":
                // 山海洪图
                this.mapList = data.list;
                break;
              case "15":
                // 人物志
                this.figureList = data.list;
                break;
              case "14":
                // 灵宝录
                this.treasureList = data.list;
                break;
              case "13":
                // 妖魔志
                this.devIlList = data.List;
                break;
              default:
                console.log("收到错误参数");
                break;
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async hasQueryFirst(mid) {
      const firstList = await getChildList({ mid: 12 });
      const bookList = firstList.data;
      // console.log(bookList);
      this.bookList = bookList;
      getArticleList({ mid }).then(({ data }) => {
        const groupItemLIst = data.list;
        console.log("groupItemLIst", groupItemLIst);
        if (groupItemLIst.length) {
          this.isShowExpect = false;
          this.groupItemLIst = groupItemLIst;

          if (groupItemLIst.length > 0) {
            const id = groupItemLIst[0].id;
            this.requestDetaile(parseInt(id));
            // console.log(detail);
            this.readId = id;
          }
        } else {
          this.isShowExpect = true;
          // this.groupItemLIst = [];
        }
        // this.groupItemLIst = data.list;
        // this.groupItemLIst = data.list;
        // const id = data.list[0].id;
        // this.readId = id;
        // this.requestDetaile(parseInt(id));
      });
      this.firstGetAllData();
    },
    imgLoad() {
      console.log("ok");
      this.isImgLoad = true;
    },
    playAudio() {
      var music = this.$refs.vd;
      music.play();
    },
  },
  watch: {
    readId(newValue) {
      console.log(newValue);
      // if (newValue <= this.groupItemLIst.length - 4) {
      //   this.translateX = -newValue * 2;
      // }
    },
  },
  mounted() {
    // const Bscroll = Bscroll;
    this.increment({ status: 4 });
    this.$nextTick(() => {
      this.scroll = new BScroll(this.$refs.wrapper, {});
    });
    // console.log(this.$route);
    const mid = this.$route.query.mid || "15";

    if (mid) {
      this.isShowDetail = true;
      this.checkGroup = mid;
      this.hasQueryFirst(mid);
    } else {
      this.firstGetAllData(mid);
    }
  },
};
</script>


<style lang="scss" scoped>
@media (min-width: 751px) {
  .container {
    // box-sizing: border-box;
    // padding-top: 43px;
    min-height: calc(100% - 133px);
    background: url(../../assets/img/data/bg.png) no-repeat bottom;
    background-size: cover;
    padding-top: 133px;
  }

  .pageData-title {
    display: flex;
    // justify-content: center;
    background-image: url(../../assets/img/data/titleWrap.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding-left: 78px;
    // margin-bottom: 160px;

    .title {
      height: 84px;
      width: 248px;
      line-height: 84px;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 28px;
      a {
        color: #15385b;
      }
    }
    .activeTitle {
      background: url(../../assets/img/newsCheckBoeder.png);
      background-size: cover;
      // width: 248px;
      padding-left: 65px;
      width: 258px;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }
  .pageData-wrap {
    position: relative;
    margin: 0 58px;
    height: 476px;
    margin-top: 160px;
    .pageData-content {
      // position: absolute;
      // top: 0;
      // left: 0;
      // z-index: 1;
      // padding-left: 200px;
      display: flex;
      justify-content: center;
      .book-wrap {
        display: inline-block;
        width: 202px;
        height: 284px;
        background: url(../../assets/img/book.png);
        background-size: cover;
        position: relative;
        margin-left: 40px;
        margin-right: 140px;
        span {
          display: inline-block;
          position: absolute;
          top: 30px;
          right: 50px;
          width: 20px;
          font-size: 24px;
          color: #5b341f;
        }
      }
    }
  }

  .detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 40px;
    .detail-left {
      // width: 255px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 210px;
      height: 508px;
      margin-right: 45px;
      background: url(../../assets/img/data/white.png) no-repeat center;
      background-size: contain;
    }
    .detail-right {
      width: 1108px;
      height: 604px;
      background: url(../../assets/img/data/juanzhou2.png);
      background-size: cover;
    }
  }

  .title-wrap {
    font-size: 20px;
    text-align: center;
    line-height: 36px;
    // margin-top: 66px;
    color: #434343;
    position: relative;

    .group {
      position: relative;
      width: 66%;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .group::before {
    content: "";
    position: absolute;
    top: 13px;
    left: 1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid #4b4c4e;
    transform: rotate(45deg);
  }

  .activeGroup {
    background: url(../../assets/img/data/group.png) no-repeat top right;
    color: #a37100;
    background-size: contain;
    .group::before {
      display: none;
    }
  }

  .content-wrap {
    display: flex;
    align-items: center;
    height: 100%;
    .wrapper {
      flex: 1;
      width: 896px;
      // height: 460px;
      overflow: auto;
      padding-left: 20px;
      .book-content {
        transition-duration: 2s;
      }
    }
    .to-left,
    .to-right {
      width: 105px;
      height: 100%;
    }
    .to-left {
      background: url(../../assets/img/data/left.png) no-repeat;
      background-position: center right;
    }
    .to-right {
      background: url(../../assets/img/data/right.png) no-repeat;
      background-position: center left;
    }
    .disabledLeft {
      background: url(../../assets/img/data/disabled.png) no-repeat;
      background-position: center right;
    }
    .disabledRight {
      background: url(../../assets/img/data/disabled.png) no-repeat;
      background-position: center right;
      transform: rotate(180deg);
    }
  }

  .noneKeyDetail,
  .hasKeyDetail,
  .contentKeyDetail {
    display: flex;
    // justify-content: flex-end;
    height: 462px;
    letter-spacing: 4px;
    line-height: 26px;
    color: #4f2d08;
    direction: rtl;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
  }

  .hasKeyDetail {
    align-items: center;
    .title {
      font-size: 28px;
      margin-left: 30px;
      line-height: 1.5;
      width: 40px;
      // writing-mode: vertical-rl;
    }
    .attributes-item {
      height: 100%;
      margin-left: 4px;
      font-size: 18px;
      div {
        width: 20px;
        overflow-wrap: break-word;
        // height: 50%;
        min-height: 50%;
        text-align: left;
        writing-mode: vertical-lr;
      }
      p {
        display: inline-block;
      }

      .identity {
        font-size: 18px;
      }
      strong {
        font-weight: bold;
      }
    }
    .rl {
      height: 100%;
      margin-left: 4px;
      // margin-right: -10px;
      writing-mode: vertical-rl;
      writing-mode: tb-rl;
      text-align: end;
      padding: 5px 0;
      box-sizing: border-box;
      font-size: 18px;
      // font-family: 'Japanese';
      font-family: Meiryo UI;

      // width: auto;
      // height: 100%;
      // margin-left: 10px;
      // writing-mode: vertical-rl;
      // direction: initial;
      // text-align: left;
      // font-size: 18px;
    }
    // .godhood {
    //         width: auto;
    //   height: 100%;
    //   margin-left: 10px;
    //   writing-mode: vertical-rl;
    //   direction: initial;
    //   text-align: left;
    //   font-size: 18px;
    // }
  }

  .contentKeyDetail {
    align-items: center;
    .title {
      font-size: 28px;
      line-height: 1.5;
      margin-left: 30px;
      width: 40px;
    }
    .attributes-item {
      height: 100%;
      margin-left: 4px;
      div {
        font-size: 18px;
        width: 20px;
        overflow-wrap: break-word;
        // min-height: 50%;
        writing-mode: vertical-lr;
      }
      i {
        display: inline-block;
        font-style: normal;
      }
    }
    .rl {
      height: 100%;
      margin-left: 4px;
      // margin-right: -10px;
      writing-mode: vertical-rl;
      writing-mode: tb-rl;
      text-align: end;
      box-sizing: border-box;
      font-size: 18px;
      padding: 5px 0;
      font-family: Meiryo UI;
    }
    .godhood {
      width: auto;
      height: 100%;
      // margin-left: 10px;
      writing-mode: vertical-rl;
      direction: initial;
      text-align: left;
      font-size: 18px;
    }
    .contentKeyContent {
      display: flex;
      height: 100%;
      // padding: 5px 0;
      padding-top: 8px;
    }
    .k3 {
      height: 100%;
      width: 20px;
      overflow-wrap: break-word;
      font-size: 18px;
      margin-left: 4px;
    }
  }

  .articleImg {
    max-height: 100%;
  }

  .content::-webkit-scrollbar {
    display: none;
  }
  .wrapper::-webkit-scrollbar {
    display: none;
  }

  .group-content {
    width: 65%;
    overflow: hidden;
    margin-left: 50px;
  }

  .title-wrap {
    .pagedataArrow {
      position: absolute;
      font-size: 10px;
      top: 88%;
      width: 15px;
      height: 16px;
      text-align: center;
      line-height: 12px;
      border-radius: 50%;
      background: #5e5c5c;
      color: #fff;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently */
    }
    .left {
      left: 40px;
    }
    .right {
      right: 24px;
    }
    .disabled {
      background: #cdcdcd;
    }
  }

  .group-item-list {
    display: flex;
    // overflow: hidden;
    flex-wrap: nowrap;
    font-size: 14px;
    line-height: 1;
    direction: rtl;
    // transform: translateX(0px);
    li {
      // margin-right: 10px;
      min-width: 1vw;
      margin-right: 2px;
      line-height: 20px;
      padding: 0 4px;
      color: #5e5c5c;
      cursor: pointer;
      div {
        writing-mode: vertical-lr;
      }
    }
    li:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: #5e5c5c;
      margin: 10px auto;
    }
    .activeReadId {
      color: #ad8c41;
    }
    .activeReadId::before {
      background: #ad8c41;
    }
  }

  .expect {
    width: 200px;
    height: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    line-height: 50px;
    div {
      width: 20px;
      font-size: 27px;
      color: #4f2d08;
    }
  }

  .detail-header {
    height: 100%;
    // display: flex;
    // flex-direction: column;
  }
  .audio-wrap {
    display: block;
    height: 10%;
    img {
      float: left;
      margin-left: 28px;
    }
    audio {
      display: none;
    }
  }
  .img-load-wrap {
    height: 90%;
    img {
      height: 100%;
    }
  }
}
</style>


<style lang="scss" scoped>
@media (max-width: 750px) {
  .container {
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 1.32rem;
    background: url(../../assets/img/data/bg.png) no-repeat bottom;
    background-size: cover;
    padding-bottom: 2rem;
  }

  .pageData-title {
    display: flex;
    background-image: url(../../assets/img/app/newsTabBg.png);
    background-size: cover;
    width: 97vw;
    margin: 0.26rem auto;
    .title {
      height: 0.9rem;
      width: 2.2rem;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
      // background-image: url(../../assets/img/tabTitleBg.png);
    }
    .activeTitle {
      background: url(../../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }
  .pageData-wrap {
    // position: relative;
    .pageData-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 80vw;
      margin: 0 auto;
      .book-wrap {
        display: inline-block;
        width: 2.5rem;
        height: 3.3rem;
        background: url(../../assets/img/book.png);
        background-size: cover;
        position: relative;
        margin-bottom: 1rem;
        span {
          display: inline-block;
          position: absolute;
          top: 0.29rem;
          right: 0.5rem;
          width: 0.4rem;
          line-height: 0.31rem;
          color: #5b341f;
          font-size: 0.28rem;
        }
      }
    }
  }

  .detail-container {
    margin-top: 0.2rem;
    .detail-left {
      display: flex;
      width: 97vw;
      box-sizing: border-box;
      padding: 0 0.6rem;
      margin: 0 auto 1rem;
      background: url(../../assets/img/app/data/secendBg.png) no-repeat;
      background-size: 100%;
      background-position: center center;
    }
    .detail-right {
      width: 97vw;
      margin: 0 auto;
      background-repeat: no-repeat;
      padding-bottom: 0.5rem;
      box-sizing: border-box;
      background-origin: content-box;
    }
    .content-top {
      background-image: url(../../assets/img/app/data/top.png);
      background-size: 100% 100%;
      height: 0.4rem;
    }
    .content-bottom {
      background-image: url(../../assets/img/app/data/bottom.png);
      background-size: 100% 100%;
      height: 0.4rem;
    }
    .content-wrap {
      background-image: url(../../assets/img/app/data/content.png);
      background-size: 100%;
      // background-size: 100%;
      // background-position: 0 585px;
    }
  }

  .title-wrap {
    display: flex;
    font-size: 20px;
    text-align: center;
    line-height: 36px;
    // margin-top: 66px;
    color: #434343;
    // position: relative;

    .group {
      // position: relative;
      height: 2.5rem;
      width: 0.5rem;
      // margin: 0 auto;
      background: url(../../assets/img/app/data/secendCheck.png) no-repeat top
        center;
      background-size: contain;
      font-size: 0.2rem;
      line-height: 0.28rem;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        line-height: 0.28rem;
        width: 0.24rem;
      }
    }
  }

  .activeGroup {
    flex: 1;
    color: #a37100;
    background-size: contain;
    .group {
      background: url(../../assets/img/app/data/secendCheckActive.png) no-repeat
        top center;
      background-size: contain;
    }
  }

  .content-wrap {
    padding: 0 0.5rem;
    box-sizing: border-box;
    .wrapper {
      overflow: auto;
      padding-left: 20px;
      .book-content {
        transition-duration: 2s;
      }
    }
    .to-left,
    .to-right {
      width: 105px;
      height: 100%;
    }
    .to-left {
      background: url(../../assets/img/data/left.png) no-repeat;
      background-position: center right;
    }
    .to-right {
      background: url(../../assets/img/data/right.png) no-repeat;
      background-position: center left;
    }
    .disabledLeft {
      background: url(../../assets/img/data/disabled.png) no-repeat;
      background-position: center right;
    }
    .disabledRight {
      background: url(../../assets/img/data/disabled.png) no-repeat;
      background-position: center right;
      transform: rotate(180deg);
    }
  }

  .noneKeyDetail,
  .hasKeyDetail,
  .contentKeyDetail {
    color: #4f2d08;
    // height: 70vh;
    overflow: auto;
    img {
      display: block;
      width: 100%;
    }
  }

  .hasKeyDetail {
    align-items: center;
    .title {
      font-size: 0.6rem;
      // margin-left: 30px;
      text-align: center;
      margin: 0.4rem 0;
      // writing-mode: vertical-rl;
    }
    .attributes-item {
      // height: 100%;
      margin: 0.14rem 0.2rem;
      font-size: 0.3rem;
      line-height: 0.34rem;
      div {
        // width: 20px;
        // overflow-wrap: break-word;
        // height: 50%;
        // min-height: 50%;
      }
    }
    .rl {
      margin: 0.12rem 0.2rem;
      font-size: 0.3rem;
      line-height: 0.38rem;
      // font-family: Meiryo UI;
    }
  }

  .contentKeyDetail {
    align-items: center;
    .title {
      font-size: 0.6rem;
      text-align: center;
      margin: 0.4rem 0;
    }
    .attributes-item {
      margin: 0.14rem 0.2rem;
      font-size: 0.3rem;
      line-height: 0.38rem;
    }
  }

  .content::-webkit-scrollbar {
    display: none;
  }
  .wrapper::-webkit-scrollbar {
    display: none;
  }

  .group-content {
    width: 3rem;
    display: flex;
    overflow: hidden;
    // margin-left: 0.2rem;
  }

  .title-wrap {
    .pagedataArrow {
      // position: absolute;
      font-size: 0.3rem;
      // top: 64%;
      width: 0.4rem;
      height: 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      // line-height: 0.4rem;
      border-radius: 50%;
      background: #5e5c5c;
      color: #fff;
      cursor: pointer;
    }
    .left,
    .right {
      margin: 21% 0.2rem 0;
    }

    .disabled {
      background: #cdcdcd;
    }
  }

  .group-item-list {
    display: flex;
    // overflow: hidden;
    flex-wrap: nowrap;
    font-size: 0.2rem;
    line-height: 1;
    // transform: translateX(0px);
    li {
      margin-right: 0.16rem;
      // margin-right: 0.08rem;
      line-height: 0.22rem;
      padding: 0.5rem 0.05rem 0rem;
      color: #5e5c5c;
      height: 88%;
      width: 0.18rem;
      div {
        font-size: 0.24rem;
        writing-mode: vertical-lr;
      }
    }
    li:before {
      content: "";
      display: block;
      width: 0.04rem;
      height: 0.04rem;
      background: #5e5c5c;
      margin: 0.1rem auto;
    }
    .activeReadId {
      color: #ad8c41;
    }
    .activeReadId::before {
      background: #ad8c41;
    }
  }

  .expect {
    width: 0.5rem;
    font-size: 0.4rem;
    text-align: center;
    margin: 0 auto;
    padding: 2rem 0;
    line-height: 1.8;
  }

  .audio-wrap {
    margin-top: 0.4rem;
    height: 0.6rem;
    img {
      height: 100%;
      width: auto;
    }
    audio {
      display: none;
    }
  }
}
</style>